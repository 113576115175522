import bgImage from "./resources/fondo_landing.jpg"
import logo from "./resources/logo_interathletics.svg"
import underConstr from "./resources/under_construction.svg"

function App() {
  return (
    <div style={styles.container}>
      <div style={styles.logoDiv}>
        <img src={logo} style={styles.logo} alt="Interathletics Logo" />
        <span style={styles.management} >MANAGEMENT</span>
        <div style={styles.constrDiv}>
          <img src={underConstr} style={styles.constr} className="rotate" alt="Under Construction" />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoDiv: {
    position: 'relative',
    width: 700,
    maxWidth: "90%",
    minWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: "100%"
  },
  management: {
    marginTop: 18,
    color: 'white',
    fontSize: 30,
    letterSpacing: 8,
  },
  constrDiv: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '-10vh',
    transform: 'translateY(100%)',
  },
  constr: {
    maxWidth: 200,
    width: "30vw",
    minWidth: 160,
  }
}

export default App;
